import { functions } from "../../firebase"

export const loadSuggestionBoxData = async (responseId: string | undefined): Promise<any> => {
    if (!responseId) {
        return Promise.reject()
    }

    var getSuggestBoxContent = functions.httpsCallable('callableGetSuggestionBoxContentFn')

    try {
        const result = await getSuggestBoxContent({ responseId: responseId })

        if (result.data.success) {
            return Promise.resolve(result.data.data)
        } else {
            return Promise.reject()
        }
    } catch (error) {
        return Promise.reject()
    }
}

export const saveSuggestion = async (
    responseId: string,
    content: string,
    languageTag: string
): Promise<any> => {
    const createSuggestion = functions.httpsCallable('callableCreateSuggestionFn')

    try {
        const result = await createSuggestion({
            responseId: responseId,
            content: content,
            languageTag: languageTag
        })

        if (result.data.success) {
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
    } catch (error) {
        return Promise.reject()
    }
}
