import {
    Button,
    FormControl,
    HStack,
    Select,
    Spacer,
    Text,
    Textarea,
    VStack
} from '@chakra-ui/react'
import { useState } from 'react'

interface FeedbackBoxProps {
    maxCharacters?: number
    defaultLanguage?: string
    buttonText?: string
    sending?: boolean
    sendingText?: string
    placeholderText?: string
    onSend?: (text: string, language: string) => void
}

export const FeedbackBox = ({
    maxCharacters = 280,
    defaultLanguage = "en",
    buttonText = "Send",
    sending = false,
    sendingText = "Sending...",
    placeholderText = "My 2 cents",
    onSend = (_: string, __: string) => { }
}: FeedbackBoxProps) => {
    const [feedbackText, setFeedbackText] = useState("")
    const [languageText, setLanguageText] = useState(defaultLanguage)
    const onFeedbackTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedbackText(e.target.value)
    const onLanguageTextChange = (e: React.ChangeEvent<HTMLSelectElement>) => setLanguageText(e.target.value)
    const characterSize = feedbackText.length

    return (
        <VStack
            shadow="sm"
            width="full"
            borderWidth="2px"
            borderRadius="md"
            overflow="hidden"
            padding={2}
        >
            <FormControl>
                <Textarea
                    value={feedbackText}
                    onChange={onFeedbackTextChange}
                    maxLength={maxCharacters}
                    resize="none"
                    rows={5}
                    placeholder={placeholderText}
                />
            </FormControl>
            <HStack width="full">
                <Select
                    value={languageText}
                    onChange={onLanguageTextChange}
                    size="sm"
                    borderRadius="md"
                    width="unset">
                    <option value="en">en</option>
                    <option value="es">es</option>
                    <option value="fr">fr</option>
                    <option value="-">-</option>
                </Select>
                <Spacer />
                <Text fontSize={10}>{characterSize} / {maxCharacters}</Text>
                <Button
                    size="sm"
                    variant="outline"
                    isLoading={sending}
                    loadingText={sendingText}
                    onClick={() => onSend(feedbackText, languageText)}
                >{buttonText}</Button>
            </HStack>
        </VStack>
    )
}
