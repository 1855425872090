import React, { ReactNode } from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Divider,
    Editable,
    EditableInput,
    EditablePreview,
    EditableProps,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Select,
    Spacer,
    Text,
    Textarea,
    UseEditableReturn,
    VStack,
} from '@chakra-ui/react'
import {
    Form,
    Formik,
    FormikErrors,
    FormikProps,
    FormikValues,
    useField,
    useFormikContext,
} from 'formik'
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

interface SimpleFormletProps {
    fields: SimpleFormFieldProps[],
    isReadonly?: boolean,
    isInlineEditable?: boolean,
    isCondensed?: boolean,
}

interface SimpleFormButtonsProps {
    submitText?: string,
    cancelText?: string,
    submittingText?: string,
    onCancel: () => void,
}

interface SimpleFormProps<TValues>
    extends SimpleFormletProps,
    SimpleFormButtonsProps {
    initialValues: TValues,
    validate?: (values: TValues) => void | object | Promise<FormikErrors<TValues>>,
    onSubmit: (values: TValues) => Promise<void>,
}

interface SimpleFormViewerProps {
    fields: SimpleFormFieldProps[],
    isCondensed?: boolean,
    values: FormikValues,
}

interface SimpleFormContainerProps<TValues> {
    initialValues: TValues,
    validate?: (values: TValues) => void | object | Promise<FormikErrors<TValues>>,
    onSubmit: (values: TValues) => Promise<void>,
    children: ReactNode,
}

type SimpleFormFieldProps = (
    SimpleFormInputFieldProps |
    SimpleFormSelectFieldProps
)

interface SimpleFormInputFieldProps {
    name: string,
    label: string,
    type: "text" | "number" | "email" | "phone" | "textarea" | "date",
    placeholder?: string,
    helperText?: string,
    isRequired?: boolean,
}

interface SimpleFormSelectFieldProps {
    name: string,
    label: string,
    data: { key: string, value: string }[],
    placeholder?: string,
    helperText?: string,
    isRequired?: boolean,
}

type SimpleFormInputFieldWithFormProps = (
    SimpleFormInputFieldProps &
    SimpleFormWithFormProps
)

type SimpleFormSelectFieldWithFormProps = (
    SimpleFormSelectFieldProps &
    SimpleFormWithFormProps
)

type SimpleFormFieldWithFormProps = (
    SimpleFormFieldProps &
    SimpleFormWithFormProps
)

interface SimpleFormWithFormProps {
    formReadOnly: boolean,
    formInlineEditable: boolean,
}

const SimpleFormEditable = (props: EditableProps) => {
    const isEditable = !props.isDisabled

    return (
        <Editable {...props}>
            {({ isEditing, onSubmit, onCancel, onEdit }: UseEditableReturn) => (
                <>
                    <Flex>
                        <EditablePreview />
                        <Spacer />
                        {!isEditing && isEditable && <IconButton aria-label="Edit" size="sm" icon={<EditIcon />} onClick={onEdit} />}
                    </Flex>
                    <EditableInput />
                    {isEditing && isEditable && <Center pt={2}>
                        <ButtonGroup justifyContent="center" size="sm">
                            <IconButton aria-label="Save" icon={<CheckIcon />} onClick={onSubmit} />
                            <IconButton aria-label="Close" icon={<CloseIcon />} onClick={onCancel} />
                        </ButtonGroup>
                    </Center>}
                </>
            )}
        </Editable>
    )
}

const SimpleFormInputField = ({
    label,
    type,
    placeholder = "",
    helperText,
    isRequired = false,
    formReadOnly,
    formInlineEditable,
    ...props
}: SimpleFormInputFieldWithFormProps) => {
    const validate = (value: string): string | void => {
        if (isRequired && !value) {
            return "Required"
        }

        // eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        if (type === "email" && !filter.test(value)) {
            return "Email is not valid"
        }
    }
    const [field, meta, helpers] = useField({ ...props, validate: validate })
    const { setValue } = helpers

    return (
        <>
            <FormControl isRequired={isRequired} isInvalid={meta.touched && !!meta.error}>
                <FormLabel htmlFor={props.name}>{label}</FormLabel>
                {
                    formReadOnly
                        ? (
                            <SimpleFormEditable
                                isDisabled={!formInlineEditable}
                                mt={-3}
                                defaultValue={meta.value}
                                onSubmit={setValue}
                            />
                        )
                        : type === "textarea"
                            ? <Textarea {...field} {...props} placeholder={placeholder} />
                            : <Input {...field} {...props} type={type} placeholder={placeholder} />
                }
                <FormErrorMessage>{meta.error}</FormErrorMessage>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    )
}

const SimpleFormSelectField = ({
    label,
    data,
    placeholder = "",
    helperText,
    isRequired = false,
    formReadOnly,
    formInlineEditable,
    ...props
}: SimpleFormSelectFieldWithFormProps) => {
    const validate = (value: string): string | void => {
        if (isRequired && !value) {
            return "Required"
        }
    }
    const [field, meta, helpers] = useField({ ...props, validate: validate })
    const { setValue } = helpers

    return (
        <>
            <FormControl isRequired={isRequired} isInvalid={meta.touched && !!meta.error}>
                <FormLabel htmlFor={props.name}>{label}</FormLabel>
                {
                    formReadOnly
                        ? (
                            <SimpleFormEditable
                                isDisabled={!formInlineEditable}
                                mt={-3}
                                defaultValue={meta.value}
                                onSubmit={setValue}
                            />
                        )
                        : (
                            <Select {...field} {...props} placeholder={placeholder}>
                                <option key="default_unused" value=""></option>
                                {
                                    data.map(row => {
                                        return (
                                            <option key={row.key} value={row.key}>{row.value}</option>
                                        )
                                    })
                                }
                            </Select>
                        )
                }
                <FormErrorMessage>{meta.error}</FormErrorMessage>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    )
}

const getFormField = (props: SimpleFormFieldWithFormProps): ReactNode => {
    const formProps = props as SimpleFormWithFormProps

    if ((props as SimpleFormInputFieldProps).type) {
        const fieldProps = props as SimpleFormInputFieldProps
        return (
            <SimpleFormInputField
                {...fieldProps}
                {...formProps}
                key={props.name}
            />
        )
    } else if ((props as SimpleFormSelectFieldProps).data) {
        const fieldProps = props as SimpleFormSelectFieldProps
        return (
            <SimpleFormSelectField
                {...fieldProps}
                {...formProps}
                key={props.name}
            />
        )
    }

    return null
}

const SimpleFormContainer = <TValues extends FormikValues>({
    initialValues,
    validate,
    onSubmit,
    children,
}: SimpleFormContainerProps<TValues>) => {
    return (
        <Box>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setSubmitting(true)
                        await onSubmit(values)
                    } catch (error) {
                        console.log(error)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {(props: FormikProps<TValues>) => (
                    <Form onSubmit={props.handleSubmit}>
                        {children}
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

const SimpleFormlet = ({
    fields,
    isReadonly = false,
    isInlineEditable = false,
    isCondensed = false,
}: SimpleFormletProps) => {
    const formProps = {
        formReadOnly: isReadonly,
        formInlineEditable: isInlineEditable
    }

    return (
        <VStack spacing={isCondensed ? 1 : 4}>
            {fields.map(field => (
                <Box key={field.name} width="full">
                    {
                        getFormField({
                            ...field,
                            ...formProps
                        })
                    }
                </Box>
            ))}
        </VStack>
    )
}

const SimpleFormButtons = ({
    submitText = "Submit",
    cancelText = "Cancel",
    submittingText = "Submitting",
    onCancel,
}: SimpleFormButtonsProps) => {
    const { isSubmitting } = useFormikContext()

    return (
        <HStack my={4}>
            <Spacer />
            <Button
                colorScheme="teal"
                isLoading={isSubmitting}
                loadingText={submittingText}
                type="submit"
            >
                {submitText}
            </Button>
            {!isSubmitting && <Button
                colorScheme="red"
                variant="outline"
                type="button"
                onClick={onCancel}
            >
                {cancelText}
            </Button>}
        </HStack>
    )
}

const SimpleForm = <TValues extends FormikValues>(
    props: SimpleFormProps<TValues>
) => {
    const containerProps = { ...props, children: null } as SimpleFormContainerProps<TValues>
    const formletProps = props as SimpleFormletProps
    const buttonsProps = props as SimpleFormButtonsProps

    return (
        <SimpleFormContainer<TValues> {...containerProps}>
            <SimpleFormlet {...formletProps} />
            <Divider my={4} />
            <SimpleFormButtons {...buttonsProps} />
        </SimpleFormContainer>
    )
}

const getFormFieldDisplay = (
    label: string,
    value: string,
    isRequired: boolean,
): ReactNode => {
    return (
        <VStack key={label} width="full" alignItems="left" spacing={-1}>
            <Text>
                <b>{label}</b>
                {isRequired && <span style={{ color: "red" }}> *</span>}
            </Text>
            <Text>{value || "N/A"}</Text>
        </VStack>
    )
}

const SimpleFormViewer = ({
    fields,
    values,
    isCondensed = false,
}: SimpleFormViewerProps) => {
    return (
        <VStack spacing={isCondensed ? 1 : 4}>
            {
                fields.map(field => {
                    if ((field as SimpleFormInputFieldProps).type) {
                        const { label, isRequired = false } = field as SimpleFormInputFieldProps
                        return getFormFieldDisplay(label, values[field.name], isRequired)
                    } else if ((field as SimpleFormSelectFieldProps).data) {
                        const { label, isRequired = false } = field as SimpleFormSelectFieldProps
                        return getFormFieldDisplay(label, values[field.name], isRequired)
                    }

                    return null
                })
            }
        </VStack>
    )
}

export type {
    SimpleFormProps,
    SimpleFormFieldProps,
    SimpleFormInputFieldProps,
    SimpleFormSelectFieldProps
}

export {
    SimpleForm,
    SimpleFormlet,
    SimpleFormButtons,
    SimpleFormContainer,
    SimpleFormViewer,
    SimpleFormInputField,
    SimpleFormSelectField,
}
