import { Divider, HStack, Tag, TagProps, Text, VStack } from '@chakra-ui/react'
import TimeAgo from 'react-timeago'
import { SuggestionModel } from '../../types/SuggestionBox'

interface SuggestionCardProps {
    suggestion: SuggestionModel
}

const getSentimentValence = (polarity: number): string => {
    return polarity === 0
        ? "Neutral"
        : polarity > 0
            ? "Positive"
            : "Negative"
}

const getSentimentColorScheme = (polarity: number): TagProps["colorScheme"] => {
    return polarity === 0
        ? "gray"
        : polarity > 0
            ? "green"
            : "red"
}

const SuggestionCard = ({ suggestion }: SuggestionCardProps) => {
    const { content, createdAt, sentimentScore = 0 } = suggestion
    const sentimentValence = getSentimentValence(sentimentScore)
    const colorScheme = getSentimentColorScheme(sentimentScore)

    return (
        <VStack
            shadow="sm"
            width="full"
            borderWidth="1px"
            borderRadius="md"
            align="stretch"
            padding={2}
            borderLeftWidth={2}
            borderLeftColor={`${colorScheme}.300`}
        >
            <Text>{content}</Text>
            <Divider />
            <HStack>
                <Tag size="sm" variant="outline" colorScheme={colorScheme}>
                    {sentimentValence}
                </Tag>
                <Text fontSize="sm">
                    <TimeAgo live date={createdAt} />
                </Text>
            </HStack>
        </VStack>
    )
}

export default SuggestionCard
