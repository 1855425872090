import { useEffect, useState } from 'react'
import { Box, Heading, VStack } from '@chakra-ui/react'
import { useLocation, useRoute } from 'wouter'
import { useSession } from '../../auth'
import { dataToSuggestionBoxModel, useStore } from '../../store'
import { SuggestionBoxModel, SuggestionModel } from '../../types/SuggestionBox'
import SuggestionCard from './SuggestionCard'

interface Props {

}

export interface SuggestionWithIdModel extends SuggestionModel {
    id: string
    createdAt: Date
}

const getSortedSuggestions = (suggestions: SuggestionModel[]): SuggestionModel[] => {
    return suggestions.sort((first, second) => first.createdAt > second.createdAt ? -1 : 1)
}

const LiveView = (props: Props) => {
    const user = useSession()
    const userId = user?.uid
    const [, params] = useRoute("/live/:id")
    const boxId = params?.id
    const [, setLocation] = useLocation()
    const { liveSuggestionBox } = useStore()

    const [suggestionBox, setSuggestionBox] = useState<SuggestionBoxModel | undefined>(undefined)
    const [suggestions, setSuggestions] = useState<SuggestionModel[]>([])

    useEffect(() => {
        if (!userId) return
        if (!boxId) return

        const unsubscribe = liveSuggestionBox(userId, boxId).onSnapshot(
            (snapshot) => {
                const box = dataToSuggestionBoxModel(boxId, snapshot.data())
                setSuggestionBox(box)
                setSuggestions(getSortedSuggestions(box.suggestions))
            },
            (error) => console.error(error))

        return () => { unsubscribe() }
    }, [userId, boxId, liveSuggestionBox])

    if (!userId) {
        setLocation("/login")
        return null
    }

    if (!boxId) {
        setLocation("/")
        return null
    }

    return (
        <>
            {suggestionBox && <Box maxWidth="800px" marginX="auto">
                <VStack>
                    <Heading size="md">Suggestions: {suggestionBox.responseCount}</Heading>
                    {
                        suggestions.map(suggestion => {
                            return (
                                <SuggestionCard key={suggestion.id} suggestion={suggestion} />
                            )
                        })
                    }
                </VStack>
            </Box>}
        </>
    )
}

export default LiveView
