import { useState } from 'react'
import TimeAgo from 'react-timeago'
import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Spacer,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
    Tag,
    Text,
    useToast,
} from '@chakra-ui/react'
import { SuggestionBoxModel } from '../../types/SuggestionBox'
import { ConfirmDialog } from '../../components'
import { FaCheckCircle, FaTimesCircle, FaTrashAlt } from 'react-icons/fa'
import { AiOutlineCopy, AiOutlineFundView } from 'react-icons/ai'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useCopyToClipboard } from '../../hooks'
import { responseUrl } from './utils'

type Props = {
    suggestionBox: SuggestionBoxModel,
    onView: () => Promise<void>,
    onOpen: () => Promise<void>,
    onClose: () => Promise<void>,
    onDelete: () => Promise<void>,
}

export const SuggestionBoxCard = ({
    suggestionBox,
    onView,
    onOpen,
    onClose,
    onDelete,
}: Props) => {
    const copyToClipboard = useCopyToClipboard()
    const toast = useToast()
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const boxIsOpen = suggestionBox.status === "open"

    const onOpenClicked = async () => {
        try {
            await onOpen()
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const onCloseClicked = async () => {
        try {
            await onClose()
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const onDeleteClicked = () => {
        setShowConfirmDialog(true)
    }

    const onDeleteConfirmed = async () => {
        setShowConfirmDialog(false)
        await onDelete()
    }

    const onCopyUrl = () => {
        const url = responseUrl(suggestionBox.responseId)
        const copied = copyToClipboard(url)

        if (copied) {
            toast({
                description: "URL coppied",
                status: "success",
                duration: 3000,
            })
        }
    }

    return (
        <Box
            shadow="sm"
            width="full"
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
            borderLeftWidth={5}
            borderLeftColor={boxIsOpen ? "green.300" : "yellow.300"}
        >
            <Flex width="full">
                <Stack flex="1" px={2} py={1} spacing={1} width="full">
                    <Flex width="full">
                        <Heading py={2} fontSize="md" isTruncated>
                            {suggestionBox.title}
                        </Heading>
                    </Flex>
                    <Divider />
                    <Text pb={2} width="full">
                        {suggestionBox.content}
                    </Text>
                    <Flex>
                        <Stat flex="1">
                            <StatLabel>Responses</StatLabel>
                            <StatNumber fontSize="md">{suggestionBox.responseCount}</StatNumber>
                        </Stat>
                        <Stat flex="1">
                            <StatLabel>Last Response</StatLabel>
                            <StatNumber fontSize="md">
                                {
                                    suggestionBox.lastResponseAt
                                        ? <TimeAgo live date={suggestionBox.lastResponseAt} />
                                        : "..."
                                }
                            </StatNumber>
                        </Stat>
                        <Stat flex="1">
                            <StatLabel>Created</StatLabel>
                            <StatNumber fontSize="md">
                                <TimeAgo date={suggestionBox.createdAt} />
                            </StatNumber>
                        </Stat>
                    </Flex>
                    <Divider />
                    <HStack spacing={5}>
                        <Tag>{suggestionBox.pricingPlan}</Tag>
                        <Spacer />
                        <Button onClick={onView} size="sm" leftIcon={<AiOutlineFundView />}>
                            Live
                        </Button>
                        <Button onClick={onCopyUrl} size="sm" leftIcon={<AiOutlineCopy />}>
                            Copy URL
                        </Button>
                        <Menu placement="bottom-end">
                            <MenuButton
                                as={IconButton}
                                icon={<ChevronDownIcon />}
                                size="sm"
                                transition="all 0.2s"
                                borderRadius="md"
                                borderWidth="1px"
                                _hover={{ bg: "gray.100" }}
                                _focus={{ outline: 0, boxShadow: "outline" }}
                            />
                            <MenuList>
                                <MenuGroup title="Status">
                                    <MenuItem onClick={onOpenClicked} isDisabled={boxIsOpen}>
                                        <FaCheckCircle />
                                        <span style={{ marginLeft: "10px" }}>Open Suggestion Box</span>
                                    </MenuItem>
                                    <MenuItem onClick={onCloseClicked} isDisabled={!boxIsOpen}>
                                        <FaTimesCircle />
                                        <span style={{ marginLeft: "10px" }}>Close Suggestion Box</span>
                                    </MenuItem>
                                </MenuGroup>
                                <MenuGroup title="Delete">
                                    <MenuItem onClick={onDeleteClicked}>
                                        <FaTrashAlt />
                                        <span style={{ marginLeft: "10px" }}>Delete Suggestion Box</span>
                                    </MenuItem>
                                </MenuGroup>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Stack>
            </Flex>
            <ConfirmDialog
                headerText="Confirm Delete"
                confirmButtonText="Delete"
                isOpen={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={onDeleteConfirmed}
            />
        </Box>
    )
}
