import { Heading, VStack } from "@chakra-ui/react"
import { FaCheckCircle } from "react-icons/fa"
import { BlankNavbar, CenteredMiddleBox, Main } from "../../components"

interface Props {

}

export const ThankYouDenied = (props: Props) => {
    return (
        <Main>
            <BlankNavbar />
            <CenteredMiddleBox p={5}>
                <VStack>
                    <FaCheckCircle color="green" size="30px" />
                    <Heading>Thank you for taking your Privacy seriously!</Heading>
                </VStack>
            </CenteredMiddleBox>
        </Main>
    )
}
