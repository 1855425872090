import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ButtonProps
} from '@chakra-ui/react'

export interface ConfirmDialogProps {
    headerText?: string,
    bodyText?: string,
    confirmButtonText?: string,
    cancelButtonText?: string,
    confirmButtonColorScheme?: ButtonProps["colorScheme"],
    cancelButtonColorScheme?: ButtonProps["colorScheme"],
    cancelRef?: React.RefObject<any>,
    isOpen: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export const DefaultConfirmDialogProps: ConfirmDialogProps = {
    headerText: "Confirm",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    bodyText: "Confirm",
    isOpen: false,
    onClose: () => { },
    onConfirm: () => { },
}

export const ConfirmDialog = ({
    headerText = "Confirmation",
    bodyText = "Are you sure?",
    confirmButtonText = "Yes",
    cancelButtonText = "Cancel",
    confirmButtonColorScheme = "red",
    cancelButtonColorScheme = "gray",
    cancelRef,
    isOpen,
    onClose = () => { },
    onConfirm,
}: ConfirmDialogProps) => {
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {headerText}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {bodyText}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme={cancelButtonColorScheme} ref={cancelRef} onClick={onClose}>
                            {cancelButtonText}
                        </Button>
                        <Button colorScheme={confirmButtonColorScheme} onClick={onConfirm} ml={3}>
                            {confirmButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
