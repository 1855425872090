import { useEffect } from 'react'
import {
    Alert,
    AlertIcon,
    Box,
    Center,
    CircularProgress,
    useDisclosure,
    useToast,
    VStack
} from '@chakra-ui/react'
import { functions } from '../../firebase'
import { useLocation } from 'wouter'
import { useSession } from '../../auth'
import { useStore } from '../../store'
import { SuggestionBoxCard } from './SuggestionBoxCard'
import { AddIcon } from '@chakra-ui/icons'
import { Fab } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { ModalForm } from './ModalForm'
import { SuggestionBoxRequestModel } from '../../types/SuggestionBox'

interface Props {

}

const Dashbard = (props: Props) => {
    const user = useSession()
    const userId = user?.uid
    const [, setLocation] = useLocation()
    const toast = useToast()
    const { loadingSuggestionBoxes, suggestionBoxes, fetchSuggestionBoxes } = useStore()
    const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    useEffect(() => {
        if (!userId) return

        fetchSuggestionBoxes(userId)
        return () => { }
    }, [userId, fetchSuggestionBoxes])

    const onViewSuggestionBox = async (id: string) => {
        setLocation(`live/${id}`)
    }

    const showWIPAlert = () => {
        toast({
            title: "Coming soon",
            description: "Work in progress",
            status: "info",
            duration: 9000,
            isClosable: true,
        })
    }

    const onOpenSuggestionBox = async (id: string) => {
        console.log(id)
        showWIPAlert()
    }

    const onCloseSuggestionBox = async (id: string) => {
        console.log(id)
        showWIPAlert()
    }

    const onDeleteSuggestionBox = async (id: string) => {
        console.log(id)
        showWIPAlert()
    }

    const onCreateSuggestionBox = async (values: SuggestionBoxRequestModel) => {
        var addSuggestBox = functions.httpsCallable('callableCreateSuggestionBoxFn');

        try {
            const result = await addSuggestBox(values);

            if (result.data.success) {
                toast({
                    title: "Suggestion Box Created",
                    description: "Your suggestion box has been created",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                onCloseModal()

                if (userId) {
                    await fetchSuggestionBoxes(userId)
                }

                return Promise.resolve()
            } else {
                toast({
                    title: result.data.error,
                    description: "Please complete all information required",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return Promise.reject()
            }
        } catch (error) {
            toast({
                title: "Error encountered",
                description: "This might not be your fault but do get in touch with us",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            console.log(error);
            return Promise.reject()
        }
    }

    if (!userId) {
        setLocation("/login")
        return null
    }

    const emptyBoxList = Object.keys(suggestionBoxes).length === 0

    return (
        <>
            <Box maxWidth="800px" marginX="auto">
                {
                    !loadingSuggestionBoxes && emptyBoxList && <Alert status="info" variant="left-accent">
                        <AlertIcon />
                        No Suggestion Boxes created. Use the button at the "Bottom Right" to created one.
                    </Alert>
                }
                <VStack>
                    {
                        Object.keys(suggestionBoxes).map(id => (
                            <SuggestionBoxCard
                                key={id}
                                suggestionBox={suggestionBoxes[id]}
                                onView={() => onViewSuggestionBox(id)}
                                onOpen={() => onOpenSuggestionBox(id)}
                                onClose={() => onCloseSuggestionBox(id)}
                                onDelete={() => onDeleteSuggestionBox(id)}
                            />
                        ))
                    }
                </VStack>
                {
                    loadingSuggestionBoxes && (
                        <Center width="full" mb={10}>
                            <CircularProgress isIndeterminate size="100px" thickness="4px" />
                        </Center>
                    )
                }
            </Box>
            <ModalForm
                isOpen={isModalOpen}
                onCreateSuggestionBox={onCreateSuggestionBox}
                onCancel={onCloseModal}
                onClose={onCloseModal}
            />
            <Fab
                mainButtonStyles={{ backgroundColor: '#44bb87' }}
                icon={<AddIcon />}
                event="click"
                onClick={onOpenModal}
                alwaysShowTitle={false}
                text="Add Suggestion Box"
            />
        </>
    )
}

export default Dashbard
