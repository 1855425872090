import create, { State } from "zustand"
import { db, firebase } from "../firebase"
import { SuggestionBoxModel, SuggestionModel } from "../types/SuggestionBox"

interface AppState extends State {
    loadingSuggestionBoxes: boolean
    suggestionBoxes: Record<string, SuggestionBoxModel>
    addSuggestionBox: (id: string, suggestionBox: SuggestionBoxModel) => void
    fetchSuggestionBox: (userId: string, id: string) => void
    fetchSuggestionBoxes: (userId: string) => Promise<void>
    liveSuggestionBox: (userId: string, id: string) => firebase.firestore.DocumentReference
}

export const dataToSuggestionModel = (id: string, data: any): SuggestionModel => {
    return {
        id: id,
        ...data,
        createdAt: data.createdAt.toDate()
    }
}

export const dataToSuggestionBoxModel = (id: string, data: any): SuggestionBoxModel => {
    const suggestionsData: Record<string, Omit<SuggestionModel, "id">> = data.suggestions || {}
    const suggestions = Object.keys(suggestionsData).map(responseId => {
        return dataToSuggestionModel(responseId, suggestionsData[responseId])
    })

    return {
        id: id,
        ...data,
        suggestions: suggestions,
        createdAt: data.createdAt.toDate(),
        lastResponseAt: data.lastResponseAt?.toDate()
    }
}

export const useStore = create<AppState>(
    (set): AppState => ({
        loadingSuggestionBoxes: false,
        suggestionBoxes: {},
        addSuggestionBox: (id: string, suggestionBox: SuggestionBoxModel) => {
            set(state => ({
                suggestionBoxes: {
                    ...state.suggestionBoxes,
                    [id]: { ...suggestionBox }
                }
            }))
        },
        fetchSuggestionBox: async (userId: string, id: string) => {
            try {
                const docPath = `/users/${userId}/boxes/${id}`
                const docSnap = await db.doc(docPath).get()
                const docData = docSnap.data()

                if (!docData) throw new Error("Invalid suggestion box data")

                const box = dataToSuggestionBoxModel(id, docData)
                if (!box.title) throw new Error("Invalid suggestion box data")

                set(state => ({
                    suggestionBoxes: {
                        ...state.suggestionBoxes,
                        [id]: { ...box }
                    }
                }))
            } catch (error) {
                console.log(error)
            }
        },
        fetchSuggestionBoxes: async (userId: string) => {
            try {
                set({ loadingSuggestionBoxes: true })
                const docsPath = `/users/${userId}/boxes`
                const docsSnap = await db.collection(docsPath).get()
                const mapData: Record<string, any> = {}
                docsSnap.forEach(doc => {
                    mapData[doc.id] = { ...dataToSuggestionBoxModel(doc.id, doc.data()) }
                })

                set({ suggestionBoxes: mapData, loadingSuggestionBoxes: false })
            } catch (error) {
                console.log(error)
            }
        },
        liveSuggestionBox: (userId: string, id: string): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> => {
            const docPath = `/users/${userId}/boxes/${id}`
            return db.doc(docPath)
        }
    })
)