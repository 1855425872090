import { Flex } from "@chakra-ui/react"
import {
    HeaderNavLink,
    Logo,
    Navbar
} from '.';
import { AppName } from "../utils";
import { ColorModeSwitcher } from "./ColorModeSwitcher";

export const BlankNavbar = () => {
    return (
        <Navbar>
            <Flex align="center" ml={-3}>
                <HeaderNavLink href="/" aria-label={`${AppName}, Back to homepage`}>
                    <Logo height={30} />
                </HeaderNavLink>
            </Flex>

            <Flex maxW="720px" align="right" color="gray.400">
                <ColorModeSwitcher />
            </Flex>
        </Navbar>
    )
}
