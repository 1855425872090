export const useCopyToClipboard = () => {
    const copyToClipboard = (text: string) => {
        const el = document.createElement('textarea')
        el.value = text
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)

        const selection = document.getSelection()
        const selected =
            selection && selection.rangeCount > 0
                ? selection.getRangeAt(0)
                : false

        el.select()
        const copied = document.execCommand('copy')
        document.body.removeChild(el)

        if (selected && selection) {
            selection.removeAllRanges()
            selection.addRange(selected)
        }

        return copied
    }

    return copyToClipboard
}
