import { useEffect, useState } from "react"
import { Box, Heading, Text, VStack } from "@chakra-ui/react"
import { FeedbackBox } from "./FeedbackBox"

interface SuggestionFormProps {
    question: string
    onSubmit: (suggestionText: string, languageTag: string) => Promise<void>
}

export const SuggestionForm = ({ question, onSubmit }: SuggestionFormProps) => {
    const [submitInProgress, setSubmitInProgress] = useState(false)

    useEffect(() => {
        return () => setSubmitInProgress(false)
    }, [])

    const submitSuggesiton = async (suggestion: string, language: string) => {
        setSubmitInProgress(true)
        await onSubmit(suggestion, language)
        setSubmitInProgress(false)
    }

    return (
        <VStack width="full">
            <Heading size="sm">Question or instruction:</Heading>
            <Box
                shadow="sm"
                width="full"
                borderWidth="2px"
                borderRadius="md"
                padding={2}
            >
                <Text>{question}</Text>
            </Box>
            <br />
            <Heading size="sm">Your anonymous response:</Heading>
            <FeedbackBox
                sending={submitInProgress}
                onSend={submitSuggesiton}
            />
        </VStack>
    )
}
