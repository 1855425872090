import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import { SimpleForm, SimpleFormFieldProps } from '../../components/SimpleForm'
import { SuggestionBoxRequestModel } from '../../types/SuggestionBox'

interface Props {
    isOpen: boolean,
    onClose: () => void,
    onCreateSuggestionBox: (values: SuggestionBoxRequestModel) => Promise<void>,
    onCancel: () => void,
}

export const ModalForm = ({
    isOpen,
    onClose,
    onCreateSuggestionBox,
    onCancel
}: Props) => {
    const fields: SimpleFormFieldProps[] = [
        {
            name: "title",
            label: "Title",
            type: "text",
            placeholder: "Office Status",
            helperText: "Friendly name",
            isRequired: true,
        },
        {
            name: "content",
            label: "Question or Instruction",
            type: "textarea",
            placeholder: "In simple sentences, please tell us the changes you want in the office.",
            isRequired: true,
        },
    ]

    const initialValues: SuggestionBoxRequestModel = {
        title: "",
        content: "",
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay>
                <ModalContent>
                    <ModalHeader>New Suggestion Box</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleForm<SuggestionBoxRequestModel>
                            fields={fields}
                            initialValues={initialValues}
                            onSubmit={onCreateSuggestionBox}
                            onCancel={onCancel}
                            submitText="Create Suggestion Box"
                            submittingText="Setting up your Suggestion Box"
                        />
                    </ModalBody>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    )
}
