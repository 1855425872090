import { useEffect, useState } from "react"
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, CircularProgress, CloseButton, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { useLocation, useRoute } from "wouter"
import { Main, BlankNavbar, ConfirmDialogProps, ConfirmDialog } from "../../components"
import { SuggestionForm } from "./SuggestionForm"
import { ThankYou } from "./ThankYou"
import { loadSuggestionBoxData, saveSuggestion } from "./utils"
import { ThankYouDenied } from "./ThankYouDenied"

interface Props {

}

const ResponsePage = (props: Props) => {
    const [, params] = useRoute("/:responseId")
    const toast = useToast()
    const [, setLocation] = useLocation()
    const responseId = params?.responseId
    const [loading, setLoading] = useState(false)
    const [loadError, setLoadError] = useState<string | undefined>(undefined)
    const [boxContent, setBoxContent] = useState<string>("")
    const [suggestionSubmitted, setSuggestionSubmitted] = useState(false)
    const [suggestionConfirmed, setSuggestionConfirmed] = useState(false)
    const [suggestionDenied, setSuggestionDenied] = useState(false)
    const [showWarning, setShowWarning] = useState(true)
    const {
        isOpen: confirmDialogIsOpen,
        onOpen: openConfirmDialog,
        onClose: closeConfirmDialog } = useDisclosure()

    const onProceed = async () => {
        setSuggestionConfirmed(true)
        closeConfirmDialog()
        setLoading(true)

        try {
            const content = await loadSuggestionBoxData(responseId)
            setBoxContent(content.toString())
        } catch (error) {
            setLoadError(error.toString())
        } finally {
            setLoading(false)
        }
    }
    const privacyAlert = "If you did not get this URL in a group, please DO NOT respond, as this might might be targeted at you and not be anonymous."

    const onExit = async () => {
        setSuggestionDenied(true)
        closeConfirmDialog()
    }

    const confirmDialogProps: ConfirmDialogProps = {
        headerText: "Privacy Alert",
        confirmButtonText: "Yes, Continue",
        cancelButtonText: "No, Exit",
        cancelButtonColorScheme: "red",
        bodyText: `${privacyAlert} Do you want to continue?`,
        confirmButtonColorScheme: "green",
        isOpen: confirmDialogIsOpen,
        onClose: onExit,
        onConfirm: onProceed,
    }

    useEffect(() => {
        openConfirmDialog()
        return () => { }
    }, [responseId, openConfirmDialog])

    if (!responseId) {
        setLocation("/")
        return null
    }

    const showError = !loading && loadError
    const showForm = !loading && !loadError

    const onSubmit = async (suggestionText: string, language: string) => {
        try {
            await saveSuggestion(responseId, suggestionText, language)
            setSuggestionSubmitted(true)
        } catch (error) {
            toast({
                title: "Error on Submission",
                description: "We are sorry there was an error in submitting. Please try again",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    if (suggestionDenied) {
        return <ThankYouDenied />
    }

    if (suggestionSubmitted) {
        return <ThankYou />
    }

    return (
        <Main>
            <BlankNavbar />
            {suggestionConfirmed && <VStack mt={90} mb={150} maxWidth="500px" marginX="auto">
                {
                    loading && (
                        <Center width="full" mb={10}>
                            <CircularProgress isIndeterminate size="100px" thickness="4px" />
                        </Center>
                    )
                }
                {showError && <Center mt={10} textColor="red">
                    Sorry, we encountered an error and will fix it soon. Thanks.
                </Center>}
                {showForm && showWarning && <Alert status="warning" mb={5}>
                    <AlertIcon />
                    <Box flex="1">
                        <AlertTitle>CAUTION!</AlertTitle>
                        <AlertDescription display="block">
                            {privacyAlert}
                        </AlertDescription>
                    </Box>
                    <CloseButton onClick={() => setShowWarning(false)} position="absolute" right="8px" top="8px" />
                </Alert>}
                {showForm && <SuggestionForm question={boxContent} onSubmit={onSubmit} />}
            </VStack>}
            <ConfirmDialog
                {...confirmDialogProps}
                isOpen={confirmDialogIsOpen}
            />
        </Main>
    )
}

export default ResponsePage
